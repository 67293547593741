import * as React from 'react'
import {useContext} from "react";
import GlobalContext from "../../context/GlobalContext";
import OrsilliLottie from "../../assets/images/lotties/orsilli-lottie.json";
import {Link} from "gatsby";
import Lottie from 'react-lottie-player/dist/LottiePlayerLight'
export default function Help() {
    const gContext = useContext(GlobalContext);
    return (
        <div className="relative w-full py-16 sm:py-32">
            <div className="max-w-[1400px] mx-auto px-4 sm:px-6 xl:px-8 grid grid-cols-1 xl:grid-cols-12 gap-6 xl:gap-16">
                <div className="xl:col-span-5 xl:col-start-1 flex items-center justify-center">
                    <div className="mx-auto w-4/5 xl:w-full flex items-center justify-center">
                        <div className="w-full xl:ml-auto max-w-[400px]">
                            <Lottie
                                loop
                                animationData={OrsilliLottie}
                                play
                                style={{ maxWidth: '400px', width: '100%' }}
                            />
                        </div>
                    </div>
                </div>
                <div className="xl:col-span-7 xl:col-start-6 flex flex-col justify-center items-center lg:items-start text-justify lg:text-left">
                    <h2 className="title-section mb-6 sm:mb-10 text-center sm:text-left">{gContext.t('Serve aiuto? Contattaci!')}</h2>
                    <div dangerouslySetInnerHTML={{ __html: gContext.t('help.text') }}></div>
                    <Link to={gContext.url('contatti')} className="primary-button w-full max-w-[250px] mt-10">{gContext.t('contattaci adesso')}</Link>
                </div>
            </div>
        </div>
    )
}
